import React from 'react';
import './Footer.css';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
      <div class='social-links'>
            <Link  class='social-links-design' onClick={()=>{window.open('EDIT THIS')      }}>about us</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('mailto:team@thehonestapp.com?subject=Customer%20Support&body=Hi%20Honest%20App%20Team,')      }}>contact us</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('EDIT THIS')      }}>terms of use</Link>
            <Link  class='social-links-design' onClick={()=>{window.open('EDIT THIS')      }}>privacy policy</Link>
            {/* <Link  class='social-links-design' onClick={()=>{window.open('https://flamingoandroid.app/')}}>android</Link> */}
      </div>
      <div class='social-icons'>
            <Link
              class='social-icon-link tiktok'
              to={{ pathname: 'EDIT THIS' }}
              target='_blank'
              aria-label='Tiktok'
            >
              <i class='fab fa-tiktok fa-x' />
            </Link>
            <Link
              class='social-icon-link facebook'
              to={{ pathname: 'EDIT THIS' }}
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to={{ pathname: 'EDIT THIS' }}
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to={{ pathname: 'EDIT THIS' }}
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to={{ pathname: 'EDIT THIS' }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-twitter' />
            </Link>
            {/* <Link
              class='social-icon-link twitter'
              to={{ pathname: 'https://flamingoandroid.app/' }}
              target='_blank'
              aria-label='Twitter'
            >
              <i class='fab fa-google-play' />
            </Link> */}
          </div>
        <div class='social-media-wrap'>
          <small class='website-rights'>© Honest LLC 2025.</small>
        </div>
      </section>
    </div>
  );
}

export default Footer;

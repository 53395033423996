import React, { useState, useEffect } from 'react';
import '../../App.css';
import './HeroSection.css';
import Logo from '../../lottie/Logo.png'
import apple from './download.png'
import google from './downloadGoogle.png'
import bottom from './bottomNew.png'
import bottomAndroid from './bottomNewAndroid.png'

function HeroSection() {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWidth(window.innerWidth);
    }

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div className='hero-container'>
      <div className='hero-container-2'>
        <img 
          src={Logo}
          style={{
            height: width >= 700 ? '500px' : '250px',
            width: width >= 700 ? '640px' : '320px'
          }}
          alt="Logo"
        />
      </div>
      <text className='promo'>Couples & Relationships</text>
      {width > 670 && (
        <div className='apple-container'>
          <img className='logo' src={apple} style={{marginRight: 20}}/>
          <img className='logo' src={google} />
        </div>
      )}
      {width < 670 && (
        // <div className='bDiv'>
        //   <img className='bottom' src={/Android/i.test(navigator.userAgent) ? bottomAndroid : bottom} style={{ width: '370px' }} />
        // </div>
        <div className='apple-container'>
          <img className='logo' src={apple} style={{marginRight: 20}}/>
          <img className='logo' src={google} />
        </div>
      )}
    </div>
  );
}

export default HeroSection;
